import React, { useContext, createContext, useState, useEffect } from 'react'

import { PattrolUserContext } from 'pattrol-frontend-components'
import { sortByLabelFunction } from "./Tools";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    /* *********************************************************** */
    /*  userContext: para acesso aos dados via API                 */
    /* *********************************************************** */
    const userContext = useContext(PattrolUserContext);
    const apiEndpoint = process.env.NODE_ENV === "production"
        ? window.location.origin + '/pattrol-api-hrcolaborador/'
        : "https://localhost:44341/pattrol-api-hrcolaborador/"

    async function fetchData(dataset, userContext) {
        const resultPromise = userContext.getApi(dataset, userContext.token, apiEndpoint);
        return resultPromise.then(result => { return result; });
    }

    /* *********************************************************** */
    /*  tabelas                                                    */
    /* *********************************************************** */
    // tabFilial
    const [tabFilial, setTabFilial] = useState([]);
    const [filialReload, setFilialReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('filial', userContext).then(result => {
                if (result) {
                    setTabFilial(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, filialReload]);
    function tabFilialReload() {
        setFilialReload(filialReload + 1);
    }

    // tabFuncao
    const [tabFuncao, setTabFuncao] = useState([]);
    const [funcaoReload, setFuncaoReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('funcao', userContext).then(result => {
                if (result) {
                    setTabFuncao(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, funcaoReload]);
    function tabFuncaoReload() {
        setFuncaoReload(funcaoReload + 1);
    }

    // tabNivel
    const [tabNivel, setTabNivel] = useState([]);
    const [nivelReload, setNivelReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('nivel', userContext).then(result => {
                if (result) {
                    setTabNivel(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, nivelReload]);
    function tabNivelReload() {
        setNivelReload(nivelReload + 1);
    }

    // tabStatus
    const [tabStatus, setTabStatus] = useState([]);
    const [statusReload, setStatusReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('status', userContext).then(result => {
                if (result) {
                    setTabStatus(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, statusReload]);
    function tabStatusReload() {
        setStatusReload(statusReload + 1);
    }

    // tabSexo
    const [tabSexo, setTabSexo] = useState([]);
    const [sexoReload, setSexoReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('sexo', userContext).then(result => {
                if (result) {
                    setTabSexo(result?.sort(sortByLabelFunction));
                }

            });
        }
    }, [userContext, sexoReload]);
    function tabSexoReload() {
        setSexoReload(sexoReload + 1);
    }

    // tabEscolaridade
    const [tabEscolaridade, setTabEscolaridade] = useState([]);
    const [escolaridadeReload, setEscolaridadeReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('escolaridade', userContext).then(result => {
                if (result) {
                    setTabEscolaridade(result?.sort(sortByLabelFunction));
                }

            });
        }
    }, [userContext, escolaridadeReload]);
    function tabEscolaridadeReload() {
        setEscolaridadeReload(escolaridadeReload + 1);
    }

    // tabEstadoCivil
    const [tabEstadoCivil, setTabEstadoCivil] = useState([]);
    const [estadoCivilReload, setEstadoCivilReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('estadocivil', userContext).then(result => {
                if (result) {
                    setTabEstadoCivil(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, sexoReload]);
    function tabEstadoCivilReload() {
        setEstadoCivilReload(estadoCivilReload + 1);
    }

    // tabCidade
    const [tabCidade, setTabCidade] = useState([]);
    const [cidadeReload, setCidadeReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('cidade', userContext).then(result => {
                if (result) {
                    setTabCidade(result?.sort(sortByLabelFunction));
                }

            });
        }
    }, [userContext, cidadeReload]);
    function tabCidadeReload() {
        setCidadeReload(cidadeReload + 1);
    }

    // tabUF
    const [tabUF, setTabUF] = useState([]);
    const [ufReload, setUFReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('uf', userContext).then(result => {
                if (result) {
                    setTabUF(result?.sort(sortByLabelFunction));
                }

            });
        }
    }, [userContext, ufReload]);
    function tabUFReload() {
        setUFReload(ufReload + 1);
    }

    // tabPais
    const [tabPais, setTabPais] = useState([]);
    const [paisReload, setPaisReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('pais', userContext).then(result => {
                if (result) {
                    setTabPais(result?.sort(sortByLabelFunction));
                }
                ;
            });
        }
    }, [userContext, paisReload]);
    function tabPaisReload() {
        setPaisReload(paisReload + 1);
    }

    // tabRegime
    const [tabRegime, setTabRegime] = useState([]);
    const [regimeReload, setRegimeReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('regime', userContext).then(result => {
                if (result) {
                    setTabRegime(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, regimeReload]);
    function tabRegimeReload() {
        setRegimeReload(regimeReload + 1);
    }

    // tabDepartamento
    const [tabDepartamento, setTabDepartamento] = useState([]);
    const [departamentoReload, setDepartamentoReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('departamento', userContext).then(result => {
                if (result) {
                    setTabDepartamento(result?.sort(sortByLabelFunction));
                }

            });
        }
    }, [userContext, departamentoReload]);
    function tabDepartamentoReload() {
        setDepartamentoReload(departamentoReload + 1);
    }

    // tabGestor
    const [tabGestor, setTabGestor] = useState([]);
    const [gestorReload, setGestorReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('gestor', userContext).then(result => {
                if (result) {
                    setTabGestor(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, gestorReload]);
    function tabGestorReload() {
        setGestorReload(gestorReload + 1);
    }

    // tabPosto
    const [tabPosto, setTabPosto] = useState([]);
    const [postoReload, setPostoReload] = useState(0);
    useEffect(() => {
        if (userContext) {
            fetchData('posto', userContext).then(result => {
                if (result) {
                    setTabPosto(result?.sort(sortByLabelFunction));
                }
            });
        }
    }, [userContext, postoReload]);
    function tabPostoReload() {
        setPostoReload(postoReload + 1);
    }

    /* *********************************************************** */
    /*  component Data Provider                                    */
    /* *********************************************************** */
    return (
        <DataContext.Provider value={{
            tabFilial: tabFilial, tabFilialReload: tabFilialReload,
            tabFuncao: tabFuncao, tabFuncaoReload: tabFuncaoReload,
            tabNivel: tabNivel, tabNivelReload: tabNivelReload,
            tabStatus: tabStatus, tabStatusReload: tabStatusReload,
            tabSexo: tabSexo, tabSexoReload: tabSexoReload,
            tabEscolaridade: tabEscolaridade, tabEscolaridadeReload: tabEscolaridadeReload,
            tabEstadoCivil: tabEstadoCivil, tabEstadoCivilReload: tabEstadoCivilReload,
            tabCidade: tabCidade, tabCidadeReload: tabCidadeReload,
            tabUF: tabUF, tabUFReload: tabUFReload,
            tabPais: tabPais, tabPaisReload: tabPaisReload,
            tabRegime: tabRegime, tabRegimeReload: tabRegimeReload,
            tabDepartamento: tabDepartamento, tabDepartamentoReload: tabDepartamentoReload,
            tabGestor: tabGestor, tabGestorReload: tabGestorReload,
            tabPosto: tabPosto, tabPostoReload: tabPostoReload,
            getApiColaboradorList: () => userContext.getApi('colaborador?extended=true', userContext.token, apiEndpoint),
            getApiColaborador: (codColaborador) => userContext.getApi(`colaborador/${codColaborador}`, userContext.token, apiEndpoint),
            insertApiColaborador: (colaboradorData) => userContext.postApi('colaborador', userContext.token, colaboradorData, apiEndpoint),
            updateApiColaborador: (colaboradorData) => userContext.putApi(`colaborador/${colaboradorData.CodColaborador}`, userContext.token, colaboradorData, apiEndpoint),
            deleteApiColaborador: (codColaborador) => userContext.deleteApi(`colaborador/${codColaborador}`, userContext.token, apiEndpoint),
            getApiFichaRegistro: (codColaborador) => userContext.getApiBlob(`ficharegistro/${codColaborador}`, userContext.token, apiEndpoint),
            getApiFotoColaborador: (fileName) => userContext.getApi(`fotoColaborador/${fileName}`, userContext.token, apiEndpoint),
            uploadFotoColaborador: (imageBase64) => userContext.postApi('fotoColaborador', useContext.token, imageBase64, apiEndpoint),
            deleteFotoColaborador: (fileName) => userContext.deleteApi(`fotoColaborador/${fileName}`, userContext.token, apiEndpoint),
            updateFotoColaboradorBanco: (codColaborador) => userContext.putApi(`fotoColaborador/${codColaborador}`, userContext.token, codColaborador, apiEndpoint)
        }}>
            {children}
        </DataContext.Provider>
    );
};